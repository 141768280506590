import React, { ReactElement, useState } from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'
import './Estates.scss'
import Layout from '../../layouts/Layout/Layout'
import Seo from '../../components/Seo/Seo'
import { distanceFilter } from '../../services/getDistance'
import { ArticleTypes } from '../OurNeighborhood/OurNeighborhood'

const ArticleLayout = loadable(() =>
  import('../../layouts/ArticleLayout/ArticleLayout'),
)

const ArticleCard = loadable(() =>
  import('../../components/ArticleCard/ArticleCard'),
)

const NoResult = loadable(() => import('../../components/NoResult/NoResult'))

const DISTANCE_RADIUS = 10000

type PositionTypes = {
  lat: number
  lng: number
}

type EstatesTypes = {
  seoTitle: string
  seoDescription: string
  title: string
  slug: string
  featuredImage: string
  optimizedFeaturedImg: ImageDataLike
  videoLink: string
  shortDescription: string
  homeBanner: {
    image: string
    videoLink: string
  }
  position: PositionTypes
  tags: string[]
}

type NavigationTypes = {
  label: string
  url: string
}

type MainPropTypes = {
  data: {
    estates: EstatesTypes
    allEstates: {
      nodes: NavigationTypes[]
    }
    featuredPursuitOfPassion: {
      nodes: ArticleTypes[]
    }
    pursuitOfPassionList: {
      nodes: ArticleTypes[]
    }
    recentArticles: {
      nodes: ArticleTypes[]
    }
  }
}

const Estates = ({
  data: {
    estates,
    allEstates: { nodes: allEstates },
    featuredPursuitOfPassion: { nodes: featuredPursuitOfPassion },
    pursuitOfPassionList: { nodes: pursuitOfPassionList },
    recentArticles: { nodes: recentArticles },
  },
}: MainPropTypes): ReactElement => {
  const [featuredArticleList] = useState(
    featuredPursuitOfPassion
      .filter((featured) =>
        distanceFilter({
          position1: estates.position,
          position2: featured.position,
          distance: DISTANCE_RADIUS,
        }),
      )
      .slice(0, 1),
  )

  const [articleList] = useState(
    pursuitOfPassionList.filter((featured) =>
      distanceFilter({
        position1: estates.position,
        position2: featured.position,
        distance: DISTANCE_RADIUS,
      }),
    ),
  )

  const seoTitle =
    estates.seoTitle && estates.seoTitle !== 'no content'
      ? estates.seoTitle
      : estates.title

  const seoDescription =
    estates.seoDescription && estates.seoDescription !== 'no content'
      ? estates.seoDescription
      : estates.shortDescription

  return (
    <Layout navButtonVariant="back" returnPageUrl="/estates/">
      <Seo
        jsonData={{
          '@type': 'Article',
          description: seoDescription,
          keywords: estates.tags.join(', '),
          image: estates.featuredImage,
          url: `${process.env.GATSBY_SITE_URL}/estates/${estates.slug}/`,
        }}
        title={seoTitle}
      />
      <ArticleLayout
        title="Estates"
        asideNavItems={allEstates}
        asideArticleTitle="News & Events"
        breadcrumbs={[
          {
            url: 'estates',
            label: 'Estates',
          },
          {
            label: estates.title,
          },
        ]}
        recentArticles={recentArticles}
        contentClassName="estates"
      >
        <article className="estates">
          {featuredArticleList.map(({ optimizedFeaturedImg, id }) => (
            <div key={id} className="estates-featured">
              <GatsbyImage
                image={getImage(optimizedFeaturedImg)!}
                alt="featured image"
                className="estates-featured"
              />
            </div>
          ))}
          {articleList.length ? (
            <div className="estates-container">
              <h2 className="estates-header">Estates</h2>
              <h2 className="estates-location">{estates.title}</h2>

              <div className="estates-content">
                {articleList.map((article) => (
                  <ArticleCard
                    key={article.id}
                    data={article}
                    url={`/pursuit-of-passion/${article.slug}/`}
                  />
                ))}
              </div>
            </div>
          ) : (
            <NoResult />
          )}
        </article>
      </ArticleLayout>
    </Layout>
  )
}
export default Estates

export const pageQuery = graphql`
  query EstatesQuery($id: String!) {
    estates: estates(id: { eq: $id }) {
      ...EstatesPageArticleFields
    }
    allEstates {
      nodes {
        label: title
        url: slug
      }
    }
    featuredPursuitOfPassion: allPursuitOfPassion(
      filter: { featured: { eq: true } }
    ) {
      nodes {
        ...EstatePursuitOfPassionCardFields
      }
    }
    pursuitOfPassionList: allPursuitOfPassion {
      nodes {
        ...EstatePursuitOfPassionCardFields
      }
    }
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
  }
`
